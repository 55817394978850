.container {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: black;
}

.imessage {
  width: 40vw;
  min-width: 500px;
  margin: auto;
}

.body {
  width: 40vw;
  min-width: 500px;
  height: 90vh;
  position: relative;
  overflow: hidden;
}

.header {
  width: 40vw;
  min-width: 500px;
  height: 15vh;
  position: absolute;
  top: 0;
}

.footer {
  width: 40vw;
  min-width: 500px;
  height: 10vh;
}

a.styled-link:hover {
  color: #4d79e7;
}

button.react-scroll-to-bottom--css-ladhj-1tj0vk3 {
  visibility: hidden;
}

::-webkit-scrollbar-corner {
  background: #ffffff00;
}