.send-tweet-container {
    height: 2rem;
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    position: relative;
}

.send-tweet-padding {
    width: 40%;
    min-width: 500px;
    height: 15px;
    position: absolute;
    background-color: black;
}

.send-tweet {
    width: 87.5%;
    line-height: 1.1rem;
    border-radius: 1.1rem;
    border-style: solid;
    border-width: 0.5px;
    border-color: white;
    position: absolute;
    bottom: 0;
    margin-bottom: -4px;
    padding: 0.5rem 2.7rem 0.5rem 1rem;
    color: white;
    background-color: black;
    resize: none;
    overflow: auto;
}

.send-tweet-button {
    width: 45px;
    height: 45px;
    position: absolute;
    right: 0;
    bottom: 0;
    margin-bottom: -7.5px;
}