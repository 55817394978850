.gray-tweet {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    position: relative;
    margin: 1rem 0 1rem 1rem;
}

.gray-tail {
    width: 55px;
    height: 55px;
    position: absolute;
    margin: -55px 0 0 -10px;
    cursor: pointer;
}

.gray-message {
    max-width: 75%;
}

.gray-bubble {
    /* max-width: 75%; */
    border-radius: 1.1rem;
    display: flex;
    flex-direction: column;
    margin: 0.5rem 0.5rem 0.5rem 1.2rem;
    color: white;
    background-color: #5e5e5e;
    cursor: pointer;
}

.gray-likes {
    width: 50px;
    height: 40px;
    position: absolute;
    display: flex;
    flex-direction: row-reverse;
    align-content: center;
}

.blue-tweet {
    margin: 1rem 0 1rem 0;
}

.blue-message {
    display: flex;
    flex-direction: row-reverse;
    align-items: flex-end;
    position: relative;
}

.blue-message-body {
    max-width: 75%;
    display: flex;
    flex-direction: row-reverse;
    align-items: flex-end;
    position: relative;
}

.blue-tail {
    width: 55px;
    height: 55px;
    position: absolute;
    margin: -55px 0 0 0;
    cursor: pointer;
}

.blue-bubble {
    /* max-width: 75%; */
    border-radius: 1.1rem;
    display: flex;
    flex-direction: column;
    margin: 0.5rem 1.8rem 0.5rem 0.5rem;
    color: white;
    background-color: #4d79e7;
    cursor: pointer;
}

.blue-likes {
    width: 50px;
    height: 40px;
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.gray-tweet-text, .blue-tweet-text {
    margin: 0.7rem;
    overflow-wrap: break-word;
}

.first-like, .second-like, .third-like {
    width: 50px;
    height: 50px;
    margin-top: -10px;
}

.options-right {
    height: 50px;

}