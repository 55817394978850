.header-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: white;
    background-color: rgba(35, 35, 35, 0.9);
    backdrop-filter: blur(15px);
}

.profile-pictures-container {
    width: 60%;
    height: 100%;
    margin: 15px auto auto auto;
    display: flex;
    flex-direction: row;
    align-items: center;
}